<div class="faq-section">
  <div class="FAQ-section">

    <div class="top-part mb-30">
      <div class="blue-small-desc">{{ 'FAQ.we_collected' | translate }}</div>

      <h2 class="left-side-question" [innerHTML]="faq?.title || '' | translate"></h2>
    </div>

    <nz-collapse class="FAQ-nz-collapse" [nzExpandIconPosition]="'end'">
      @for (item of faq?.items; track item.title) {
        <nz-collapse-panel [nzHeader]="item.title | translate">
          <p class="FAQ-content-item">
            {{ item.info | translate }}
          </p>
        </nz-collapse-panel>
      }
    </nz-collapse>
  </div>

</div>
