<div class="find-template-section">
  <section>
    <div class="top-part">
      <div class="blue-small-desc">{{ 'resume_examples.solutions' | translate }}</div>

      <h2 [innerHTML]="'resume_examples.find' | translate"></h2>

      <p>{{ 'resume_examples.scratch' | translate }}</p>
    </div>

    <div class="templates-container">
      <div class="templates-list">

        <div class="pl-10 pr-10">
          <p-carousel
            #CarouselRef
            [value]="appStateService.templatesForSlide"
            [numVisible]="5"
            [numScroll]="1"
            [showNavigators]="false"
            [showIndicators]="false"
            [responsiveOptions]="responsiveOptions">
            <ng-template let-template pTemplate="item">
              <nz-spin [nzSpinning]="selectedTemplate === template" [nzSize]="'large'">
                <div class="template-item" (click)="onSelectTemplate(template)">
                  <i class="sf-icon-zoom-btn" (click)="onPreviewTemplate($event, template)"></i>

                  <img class="w-full" [src]="template.src" alt="selfcv">

                  @if (template.premium) {
                    <div class="prem">
                      <i class="sf-icon-white-star"></i>
                      <span>{{ 'premium' | translate }}</span>
                    </div>
                  } @else {
                    <div class="prem prem-free">
                      <i class="ph ph-hand-coins"></i>
                      <div class="mt-2">{{ 'free' | translate }}</div>
                    </div>
                  }

                  <sf-tm-buttons [template]="template"/>
                </div>
              </nz-spin>
            </ng-template>
          </p-carousel>

          <div class="carousel-indicators">
            <div class="indicators-dots">
              @for (dot of getDotsNumber(CarouselRef); let index = $index; track index) {
                <div
                  class="carousel-dot"
                  [class.active]="index === CarouselRef.page"
                  (click)="CarouselRef.onDotClick($event, index)"
                ></div>
              }
            </div>

            <div class="carousel-arrows">
              <div class="c-arrow mr-12" (click)="CarouselRef.navBackward($event)">
                <i class="ph ph-caret-left"></i>
              </div>
              <div class="c-arrow" (click)="CarouselRef.navForward($event)">
                <i class="ph ph-caret-right"></i>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</div>
