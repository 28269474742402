<div class="why-use-us-section bg-img-why-us-as-vector">
  <section>
    <div class="top-part">
      <div class="title-top-desc">selfcv</div>
      <h2 [innerHTML]="'why_use_us.why' | translate"></h2>
    </div>

    <div class="why-points">
      <ul>
        @for (item of whyUseUsItems; track $index) {
          <li>
            <i class="{{item.icon}}"></i>
            <span [innerHTML]="item.text | translate"></span>
          </li>
        }
      </ul>
    </div>

    <!--<button class="sf-btn-primary">View all services</button>-->
  </section>
</div>
