<div class="resources-section">
  <section>
    <div class="top-part">
      <div class="title-top-desc">selfcv</div>
      <h2 [innerHTML]="'templates_page.resources' | translate: {ngTitle}"></h2>
    </div>

    <div class="content-part">
      @for (item of resources; track $index) {
        <div class="content-item">
          <div class="left-side">
            <h3>{{ item.title | translate }}</h3>
            <p [innerHTML]="item.content | translate"></p>
          </div>

          <img [ngSrc]="item.image" width="370" height="360" alt="selfcv resume" [priority]="item.priority">
        </div>
      }
    </div>

  </section>
</div>

