import {Resource} from '@app/shared/interfaces';
import {NgOptimizedImage} from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'sf-resources-section',
  templateUrl: './resources-section.component.html',
  styleUrls: ['./resources-section.component.scss'],
  standalone: true,
  imports: [
    NgOptimizedImage,
    TranslateModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourcesSectionComponent {
  @Input() public ngTitle = '';
  @Input() public resources: Readonly<Resource[]> = [];
}
