import {TranslateModule} from "@ngx-translate/core";
import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'sf-why-use-us-section',
  templateUrl: './why-use-us-section.component.html',
  styleUrls: ['./why-use-us-section.component.scss'],
  imports: [
    TranslateModule
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WhyUseUsSectionComponent {
  public readonly whyUseUsItems = [
    {icon: 'sf-icon-premium', text: 'why_use_us.Freemium'},
    {icon: 'sf-icon-freemium', text: 'why_use_us.Professional'},
    {icon: 'sf-icon-pricing', text: 'why_use_us.Fees'},
    {icon: 'sf-icon-ATS-friendly', text: 'why_use_us.ATS'},
    {icon: 'sf-icon-live-content', text: 'why_use_us.Matching'},
    {icon: 'sf-icon-editing', text: 'why_use_us.Editing'},
  ];
}
